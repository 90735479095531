import React from 'react';
import Routes from './Routes';
import { Helmet } from 'react-helmet';
import ResponsiveHelper from 'components/ResponsiveHelper';

const isProd = process.env.REACT_APP_ENV === 'production';
const gtmId = process.env.REACT_APP_GTM_ID;
const fbPixelId = process.env.REACT_APP_FB_PIXEL_ID;

const meta = {
  title: 'GoodWork.ph Book Local Home & Spa Services in the Philippines',
  description:
    'Easily book top quality service providers on GoodWork.ph for all your home cleaning, laundry, repairs, and spa service needs. Fast service at low prices.',
  image: require('assets/images/gw-home-cleaning-banner.png'),
  type: 'website',
  url: process.env.REACT_APP_URL || 'https://www.goodwork.ph',
};

function Tracking() {
  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="title" content={meta.title} />
      <meta name="description" content={meta.description} />

      <meta property="og:type" content={meta.type} />
      <meta property="og:url" content={meta.url} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:image" content={meta.image} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={meta.url} />
      <meta property="twitter:title" content={meta.title} />
      <meta property="twitter:description" content={meta.description} />
      <meta property="twitter:image" content={meta.image} />

      {`<!-- FB App Links -->`}
      <meta property="al:android:url" content="goodworkph://app" />
      <meta property="al:android:package" content="ph.goodwork" />
      <meta property="al:android:app_name" content="GoodWork.ph" />

      <meta property="al:ios:url" content="goodworkph://app" />
      <meta property="al:ios:app_store_id" content="1444626379" />
      <meta property="al:ios:app_name" content="GoodWork.ph" />

      <meta property="al:web:should_fallback" content="false" />

      {isProd
        ? `<!-- Global site tag (gtag.js) - Google Ads: ${gtmId} -->`
        : null}
      {isProd ? (
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=AW-${gtmId}`}
        ></script>
      ) : null}

      {isProd ? (
        <script>
          {`window.dataLayer = window.dataLayer || []
          if (window.gtag === undefined) {
            window.gtag = function gtag() {
              dataLayer.push(arguments)
            }

            window.gtag('js', new Date())
            window.gtag('config', 'AW-${gtmId}')
          }`}
        </script>
      ) : null}

      {isProd ? (
        <script>
          {`!function(e,n,t){!function(e,n,t,a,r){var s=(e[n]=e[n]||{})[t]=function(){(s._q=s._q||[]).push(Array.prototype.slice.call(arguments))};s.webkey=r;for(var c=0;c<a.length;c++)s[a[c]]=function(n){return function(){var e=Array.prototype.slice.call(arguments);return e.unshift(n),(s._q=s._q||[]).push(e),s}}(a[c])}(e,"AF","Banner",["showBanner","hideBanner","disableBanners","disableTracking","setAdditionalParams"],t),function(e,n,t){var a=e.createElement("script");a.type="text/javascript",a.async=!0,a.src=n+(t?"?webkey="+t:"");var r=e.getElementsByTagName("script")[0];r.parentNode.insertBefore(a,r)}(n,"https://cdn.appsflyer.com/web-sdk/banner/latest/sdk.min.js",t)}(window,document,"a32251fc-0d1f-40d6-ba6f-b43ca8165a40");
            window.AF.Banner.showBanner();`}
        </script>
      ) : null}

      {isProd ? `<!-- Facebook Pixel Code -->` : null}
      {isProd ? (
        <script>
          {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${fbPixelId}');
            fbq('track', 'PageView');`}
        </script>
      ) : null}
      {isProd ? (
        <noscript>
          {`<img
            height="1"
            width="1"
            style="display:none"
            src="https://www.facebook.com/tr?id=${fbPixelId}&ev=PageView&noscript=1"
          />`}
        </noscript>
      ) : null}

      {isProd ? `<!-- End Facebook Pixel Code -->` : null}
    </Helmet>
  );
}

function App() {
  if (!isProd) {
    console.log('ENV:', process.env.REACT_APP_ENV);
  }

  return (
    <>
      {process.env.REACT_APP_ENV === 'development' ? (
        <ResponsiveHelper />
      ) : null}
      <Tracking />
      <Routes />
    </>
  );
}

export default App;
