const ROUTES = {
  homePage: '/',
  faq: '/faq',
  termsAndConditions: '/terms-and-conditions',
  privacyPolicyPage: '/privacy-policy',
  merchantPrivacyPolicy: '/merchant-privacy-policy',
  aboutUs: '/about-us',
};

export default ROUTES;
