import React from 'react';
import { hydrate, render } from 'react-dom';
import './tailwind.output.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './assets/fonts/Lato-Regular.ttf';
import './assets/fonts/Lato-Bold.ttf';
import './assets/fonts/Lato-BoldItalic.ttf';
import './assets/fonts/Lato-Italic.ttf';
import './assets/fonts/Lato-Light.ttf';
import './assets/fonts/Lato-LightItalic.ttf';
import './assets/fonts/Lato-Thin.ttf';
import './assets/fonts/Lato-ThinItalic.ttf';

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    rootElement,
  );
} else {
  render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    rootElement,
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
