import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import Loadable from 'react-loadable';
import ROUTES from './constants/routes';

const HomePage = Loadable({
  loader: () => import('./pages/HomePage'),
  loading: () => null,
  modules: ['HomePage'],
});

const FAQPage = Loadable({
  loader: () => import('./pages/FAQPage'),
  loading: () => null,
  modules: ['FAQPage'],
});

const TermsAndConditions = Loadable({
  loader: () => import('./pages/TermsAndConditionsPage'),
  loading: () => null,
  modules: ['TermsAndConditionsPage'],
});

const PrivacyPolicyPage = Loadable({
  loader: () => import('./pages/PrivacyPolicyPage'),
  loading: () => null,
  modules: ['PrivacyPolicyPage'],
});

const MerchantPrivacyPolicy = Loadable({
  loader: () => import('./pages/MerchantPrivacyPolicyPage'),
  loading: () => null,
  modules: ['MerchantPrivacyPolicy'],
});

const AboutUs = Loadable({
  loader: () => import('./pages/AboutUsPage'),
  loading: () => null,
  modules: ['AboutUsPage'],
});

function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path={ROUTES.homePage} component={HomePage} />
        <Route exact path={ROUTES.faq} component={FAQPage} />
        <Route
          exact
          path={ROUTES.privacyPolicyPage}
          component={PrivacyPolicyPage}
        />
        <Route
          exact
          path={ROUTES.merchantPrivacyPolicy}
          component={MerchantPrivacyPolicy}
        />
        <Route
          exact
          path={ROUTES.termsAndConditions}
          component={TermsAndConditions}
        />
        <Route
          exact
          path={ROUTES.aboutUs}
          component={AboutUs}
        />
      </Switch>
    </Router>
  );
}

export default Routes;
