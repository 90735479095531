import React from 'react';

function ResponsiveHelper() {
  return (
    <div className="p-1 bg-gray-500 xxs:bg-red-500 xs:bg-yellow-500 sm:bg-green-500 md:bg-blue-500 lg:bg-indigo-500 xl:bg-pink-600 text-center text-xs opacity-75 fixed w-full z-50">
      <p className="xxs:hidden">Default</p>
      <p className="hidden xxs:block xs:hidden">Extra extra small</p>
      <p className="hidden xs:block sm:hidden">Extra small</p>
      <p className="hidden sm:block md:hidden">Small</p>
      <p className="hidden md:block lg:hidden">Medium</p>
      <p className="hidden lg:block xl:hidden">Large</p>
      <p className="hidden xl:block ">Extra Large</p>
    </div>
  );
}

export default ResponsiveHelper;
